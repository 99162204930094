import { render, staticRenderFns } from "./App.vue?vue&type=template&id=98979c58&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/main.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&id=98979c58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98979c58",
  null
  
)

export default component.exports