var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'base-input-container',
    {
      pristine: _vm.pristine,
      dirty: _vm.dirty,
      modified: !_vm.pristine,
      'has-focus': _vm.hasFocus,
      'has-error': _vm.validable && _vm.hasError,
      'is-valid': _vm.validable && _vm.isValid,
      'is-invalid': _vm.validable && _vm.isInvalid,
      'no-padding': !_vm.hasPadding
    },
    ..._vm.styles
  ],on:{"mouseenter":_vm.onMouseEnterField,"mouseleave":_vm.onMouseLeaveField}},[(_vm.label)?_c('div',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?[_c('span',{staticClass:"required"},[_vm._v("*")])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"field-container"},[_c('div',{class:[
        'input-box',
        {'no-border': !_vm.hasBorder }
      ],on:{"click":_vm.setFocus}},[_c('div',{staticClass:"input-container"},[(_vm.fieldComponent)?[_c(_vm.fieldComponent,_vm._g(_vm._b({ref:"inputElement",tag:"InputElement",attrs:{"disabled":_vm.disabled,"required":_vm.required,"styles":_vm.styles,"display-id":_vm.displayId},on:{"input":_vm.onInput,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updateField.apply(null, arguments)},"autoclose":_vm.updateField,"focus":_vm.onFieldFocus,"blur":_vm.onFieldBlur,"invalid":_vm.onInvalid,"valid":_vm.onValid,"reset-validity":_vm.onResetValidity}},'InputElement',_vm.$attrs,false),_vm.$listeners))]:[_vm._v(" No mapping ")]],2)]),_c('div',{staticClass:"input-validation",class:{'show-validation':_vm.validable && (_vm.isValid || _vm.hasError)}},[_c('div',{staticClass:"validation-icon icon-ok"},[(_vm.isValid)?_c('img',{attrs:{"src":require("../../assets/img/svgicons/check-circle-green.svg")}}):_vm._e()]),_c('div',{staticClass:"validation-icon"},[(_vm.hasError)?_c('img',{attrs:{"src":require("../../assets/img/svgicons/x-circle-red.svg")}}):_vm._e()])])]),(_vm.helpText && !_vm.isInvalid && !_vm.disabled)?_c('div',{class:['help-text', {'show-help-text':_vm.showHelpText}]},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.helpText)}})]):_vm._e(),_c('div',{staticClass:"field-errors"},[_vm._l((_vm.fieldErrors),function(error,errorIdx){return [_c('div',{key:['ferr', _vm.label, errorIdx].join('-'),staticClass:"error"},[_vm._v(" "+_vm._s(error)+" ")])]}),(_vm.validationError)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.validationError)+" ")]):_vm._e()],2),(typeof _vm.strength == 'number')?[_c('BaseStrengthBar',{attrs:{"strength":_vm.strength},on:{"invalid":_vm.onInvalid,"valid":_vm.onValid}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }