import { render, staticRenderFns } from "./BaseTag.vue?vue&type=template&id=3967951e&scoped=true&"
import script from "./BaseTag.vue?vue&type=script&lang=js&"
export * from "./BaseTag.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/base.css?vue&type=style&index=0&id=3967951e&prod&scoped=true&lang=css&"
import style1 from "./BaseTag.vue?vue&type=style&index=1&id=3967951e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3967951e",
  null
  
)

export default component.exports