import { render, staticRenderFns } from "./SpaceForm.vue?vue&type=template&id=6a25da90&scoped=true&"
import script from "./SpaceForm.vue?vue&type=script&lang=js&"
export * from "./SpaceForm.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/forms.css?vue&type=style&index=0&id=6a25da90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a25da90",
  null
  
)

export default component.exports