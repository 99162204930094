var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.myGallery && _vm.myGallery.id)?_c('div',{staticClass:"navbar"},[_vm._l((_vm.menuLinks),function(menuLink,lIdx){return [_c('router-link',{key:'menulink' + lIdx,class:[
        'menu-link',
        {
          'router-link-active':
            menuLink.to.name.split('-')[0] == _vm.$route.name.split('-')[0],
        },
      ],attrs:{"to":menuLink.to}},[_c('div',{staticClass:"menu-element"},[(_vm.smallScreen)?_c('div',{staticClass:"element-icon"},[_c('img',{attrs:{"src":require(`@/base/assets/img/svgicons/${menuLink.icon}.svg`)}})]):_vm._e(),(!_vm.smallScreen)?_c('div',{staticClass:"element-label"},[_vm._v(" "+_vm._s(menuLink.label)+" ")]):_vm._e()])])]}),_c('div',{staticClass:"action-buttons"},[_c('BaseButton',{attrs:{"label":"Log out"},on:{"click":_vm.onLogoutButtonClick}})],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }