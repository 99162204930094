<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Gallery</h2>
          <h1>{{ itemRaw.name }}</h1>
          <div class="simple-tag">
            {{ (itemRaw.metadata.published) ? "Published" : "Not published" }}
          </div>
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <!-- <slot name="metadata" /> -->
    <section>
      <h2>Settings</h2>
      <BaseInputContainer
        v-model="item.admin.subscription"
        field-type="select"
        label="Subscription"
        placeholder=""
        :options="SUBSCRIPTION_CHOICES"
        :errors="errors.subscription"
        disabled
      />
      <SetEditor
        :item="item.gallery_to_artist"
        :errors="errors.gallery_to_artist"
        label="Tax receipt settings"
        set-model="gallery_to_artist"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      />
      <VisibilityForm
        :item="item.visibility"
        :errors="errors"
        v-on="$listeners"
      />
      <slot name="controls" />
    </section>
    <section>
      <LegalInformation
        :item="item.admin"
        :errors="errors.admin"
        disabled
        v-on="$listeners"
      />
      <h2>Identification</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Gallery's name"
        placeholder=""
        help-text="Provide a name for this gallery."
        disabled
      />
      <BaseInputContainer
        v-model="item.website"
        :errors="errors.website"
        field-type="string"
        label="Gallery's website"
        placeholder=""
        help-text="Gallery's main website URL (https://example.com)"
        disabled
      />
      <BaseInputContainer
        v-model="item.creationyear"
        :errors="errors.creationyear"
        field-type="integer"
        label="Creation year"
        placeholder=""
        help-text="Gallery's creation year"
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <section>
      <h2>Address</h2>
      <AddressForm
        :item="item.address"
        :errors="errors"
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <section>
      <h2>Public informations</h2>
      <BaseInputContainer
        v-model="item.description"
        :errors="errors.description"
        field-type="rte"
        label="Description"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.portrait"
        label="Portrait"
        :errors="errors.portrait"
        :disabled-resize="false"
        help-text="You can add PNG & JPG files. 500px minimum. 20MB maximum."
        ratio-type="portrait"
        field-type="image"
        disabled
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        help-text="Copyright/credits for portrait."
        label="Copyright"
        simple-mode
        :styles="['small-rte']"
        :errors="errors.copyright"
        disabled
      />
      <SocialNetworkForm
        :item="item.social_network"
        :errors="errors.social_network"
        disabled
      />
      <BaseInputContainer
        v-model="item.tag_set"
        :errors="errors.tag_set"
        field-type="multiselect"
        placeholder=""
        label="Tags"
        reverse-field="id"
        :data-source="$TapGallery.tag"
        disabled
      />
      <BaseInputContainer
        v-model="item.pledge_text"
        :errors="errors.pledge_text"
        field-type="rte"
        placeholder=""
        label="Pledge Text"
        :max-length="availableFields.pledge_text.max_length"
        disabled
      />
      <BaseInputContainer
        v-model="item.pledge_video"
        field-type="url"
        label="Pledge video"
        :errors="errors.pledge_video"
        placeholder=""
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <SetEditor
      :item="item.space_set"
      :errors="errors.space_set"
      label="Gallery's exhibition spaces"
      set-model="space_set"
      :can-add="false"
      :can-delete="false"
      disabled
    >
      <!-- <template slot="controls">
        <slot name="controls" />
      </template> -->
    </SetEditor>
  </div>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'
import AddressForm from '@/components/AddressForm'
import LegalInformation from '@/components/gallery/LegalInformation'
import SetEditor from '@/components/SetEditor'
import SocialNetworkForm from '@/components/SocialNetworkForm'
import VisibilityForm from '@/components/VisibilityForm'

export default {
  name: 'GalleryForm',
  components: {
    AddressForm,
    LegalInformation,
    SetEditor,
    SocialNetworkForm,
    VisibilityForm
  },
  mixins: [modelForms],
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
