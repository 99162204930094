var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    ..._vm.styles,
    'base-button-container',
    { disabled: _vm.disabled },
    { active: _vm.active },
    { 'no-label': _vm.label == null },
  ],on:{"click":_vm.onClick,"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"label"},[(_vm.label)?_c('span',{staticClass:"label-text"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.icon)?_c('img',{staticClass:"icon",attrs:{"src":_vm.iconFileURL}}):_vm._e(),_vm._t("special-icon"),(_vm.isCTANav)?_c('BaseArrow',{ref:"baseArrow",staticClass:"arrow",attrs:{"size":"default"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }