<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.instagram"
      :errors="errors.instagram"
      :required="fields.instagram.required"
      field-type="integer"
      label="Number of followers on Instagram"
      :disabled="disabled"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
    <BaseInputContainer
      v-model="item.artfacts_page"
      :errors="errors.artfacts_page"
      :required="fields.artfacts_page.required"
      field-type="string"
      label="Artfacts page (URL)"
      disabled
    />
    <BaseInputContainer
      v-model="item.artfacts"
      :errors="errors.artfacts"
      :required="fields.artfacts.required"
      field-type="toggle"
      label="Display Artfacts data"
      :disabled="disabled"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: "VisibilityForm",
  mixins: [modelForms],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default:false
    }
  },
  computed: {
    fields: function(){
      return this.$parent.availableFields.visibility.children
    },
  },
}

</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>

</style>
