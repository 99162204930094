import { render, staticRenderFns } from "./BaseLogo.vue?vue&type=template&id=6abf1a07&scoped=true&functional=true&"
import script from "./BaseLogo.vue?vue&type=script&lang=js&"
export * from "./BaseLogo.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/base.css?vue&type=style&index=0&id=6abf1a07&prod&scoped=true&lang=css&"
import style1 from "./BaseLogo.vue?vue&type=style&index=1&id=6abf1a07&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6abf1a07",
  null
  
)

export default component.exports